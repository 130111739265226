import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import FocusedOption from "./FocusedOption.jsx";
import { makeStyles } from "@mui/styles";
import colors from "src/colors.json";
import prices from "src/prices.json";
import BlueButton from "src/Elements/BlueButton";
import { AuthContext } from "src/AuthProvider";
import FullScreenLoader from "src/Elements/FullScreenLoader";
import usePaymentStyles from "../PaymentStyles.js";
import { USER_PURCHASE_SUCCESS_URI, USER_PURCHASE_FAIL_URI } from "src/contants";
import { NetworkProvider } from "src/NetworkProvider";
import poweredByStripe from 'src/Assets/PoweredByStripe.svg'
import trainers from 'src/trainers.json';

const PaymentForTrainer = () => {
  const classes = usePaymentStyles();
  const [focusedOptionId, setFocusedOptionId] = useState(null);
  const [loadingText, setLoadingText] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const trainer = searchParams.get("trainer");
  const trainerColors = colors[trainer] || colors["default"];
  const priceIds = prices[trainer] || [];
  const trainerInfo = trainers[trainer]
  const trainerName = trainerInfo["name"]
  const [priceInfo, setPriceInfo] = useState(null);

  useEffect(() => {
    const fetchAllPrices = async () => {
      try {
        const priceInfos = await NetworkProvider.fetchPriceInfo(
          loginCredentials,
          setLoginCredentials,
          trainer,
          priceIds
        );
        setPriceInfo(priceInfos);
      } catch (err) {
        alert("Failed to fetch prices");
      }
    };

    if (priceInfo == null) {
      fetchAllPrices();
    }
  }, [trainer, priceIds]);

  let logoPath;
  try {
    logoPath = require(`src/Assets/Logos/${trainer}.png`);
  } catch (error) {
    console.error(`Logo not found for trainer: ${trainer}`, error);
  }

  const handleFocusChange = (title, id) => {
    setFocusedOptionId(id);
  };

  const onSubscribeClick = async () => {
    setLoadingText("Transferring to purchase");

    try {
      const priceId = priceIds[focusedOptionId];
      const successUri = USER_PURCHASE_SUCCESS_URI + "/?trainer=" + trainer;
      const failUri = USER_PURCHASE_FAIL_URI + "/?trainer=" + trainer;
      const checkoutInfo = await NetworkProvider.fetchUserCheckoutSession(
        loginCredentials,
        setLoginCredentials,
        priceId,
        successUri,
        failUri,
        trainer
      );

      const checkout_url = checkoutInfo.checkout_url;
      window.location.href = checkout_url;
    } catch (error) {
      setLoadingText(null);
      alert("Failed to make a purchase " + error.detail);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <div className={classes.logoSection}>
          <img src={logoPath} alt="Trainer Logo" className={classes.logo} />
          <h1 className={classes.logoText}>{trainerName}</h1>
        </div>
        <div className={classes.innerContent}>
          {priceInfo ? (
            priceInfo.map((price, index) => {

              const monthlyPrice = price.recurring?.interval_count > 1
                ? `${((price.unit_amount / price.recurring.interval_count) / 100).toFixed(2)} ${price.currency}/month`
                : `${(price.unit_amount / 100).toFixed(2)} ${price.currency}/${price.recurring?.interval || 'month'}`;


              return <>
                <FocusedOption
                  key={index}
                  title={price.product_name || `Plan ${index + 1}`}
                  subtitle={price.product_description || ""}
                  price={`${(price.unit_amount / 100).toFixed(2)}`}
                  currency={`${price.currency}`}
                  isFocused={focusedOptionId === index}
                  onFocusChange={handleFocusChange}
                  footerText={monthlyPrice}
                  id={index}
                  trainerColors={trainerColors}
                />
                <div className={classes.spacer} />
              </>
            })
          ) : (
            <div className={classes.loaderContainer}>
              <div className={classes.loader}></div>
            </div>
          )}
          {priceInfo &&
            <>
              <BlueButton
                onClick={onSubscribeClick}
                style={{ backgroundColor: trainerColors.accentColor }}
                className={classes.subscribeButton}
              >
                Continue
              </BlueButton>
              <div className={classes.separatorSpacer}></div>
              <div className={classes.separator}></div>
              <div className={classes.separatorSpacer}></div>
            </>
          }
          <div className={classes.stripeFooter}>
            <img
              src={poweredByStripe}
              alt="Stripe"
              className={classes.stripeLogo}
            />
          </div>
        </div>
      </div>
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
};

export default PaymentForTrainer;
