import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import { STRIPE_PK_KEY, USER_PURCHASE_SUCCESS_URI, HOMEPAGE_URL, COMPANY_SETUP_URI } from 'src/contants';
import { AuthContext } from 'src/AuthProvider';
import BlueButton from "src/Elements/BlueButton";
import FocusedOption from "../PaymentOnboarding/PaymentForTrainer/FocusedOption";
import FullScreenLoader from "src/Elements/FullScreenLoader";

import colors from 'src/colors.json';
import prices from "src/prices.json";
import usePaymentStyles from "../PaymentOnboarding/PaymentStyles";
import poweredByStripe from 'src/Assets/PoweredByStripe.svg'

const stripePromise = loadStripe(STRIPE_PK_KEY, { locale: 'en' });

const CompanySetup = () => {

    const location = useLocation();
    const classes = usePaymentStyles();

    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const [priceInfo, setPriceInfo] = useState(null);

    const logoPath = require(`src/Assets/Logos/appscribe.png`);
    const trainerColors = colors['default']
    const priceIds = prices['default'];
    const [focusedOptionId, setFocusedOptionId] = useState(null);
    const [loadingText, setLoadingText] = useState(null);

    useEffect(() => {
        const fetchAllPrices = async () => {
            try {
                const priceInfos = await NetworkProvider.fetchPriceInfo(
                    loginCredentials,
                    setLoginCredentials,
                    'default',
                    priceIds
                );
                setPriceInfo(priceInfos);
            } catch (err) {
                alert("Failed to fetch prices");
            }
        };

        if (priceInfo == null) {
            fetchAllPrices();
        }
    }, []);

    const onSubscribeClick = async () => {

        setLoadingText("Transfering to purchase");

        try {
            const priceId = priceIds[focusedOptionId];
            const successUri = USER_PURCHASE_SUCCESS_URI;

            // TODO add back contract signing
            const failUri = HOMEPAGE_URL + COMPANY_SETUP_URI;
            const coupon = location.state?.coupon;

            const checkoutInfo = await NetworkProvider.fetchAuthorCheckoutSession(
                loginCredentials,
                setLoginCredentials,
                priceId,
                successUri,
                failUri,
                coupon
            );

            const checkout_url = checkoutInfo.checkout_url;
            window.location.href = checkout_url;
        } catch (error) {
            setLoadingText(null);
            alert("Failed to make a purchase " + error.detail);
        }
    };

    const handleFocusChange = (title, id) => {
        setFocusedOptionId(id);
    };

    return (
        <div className={classes.content}>
            <div className={classes.container}>
                <div className={classes.logoSection}>
                    <img src={logoPath} alt="Trainer Logo" className={classes.logo} />
                    <h1 className={classes.logoText}>{"Appscribe"}</h1>
                </div>
                <div className={classes.innerContent}>
                    {priceInfo ? (
                        priceInfo.map((price, index) => {

                            const monthlyPrice = price.recurring?.interval_count > 1
                                ? `${((price.unit_amount / price.recurring.interval_count) / 100).toFixed(2)} ${price.currency}/month`
                                : `${(price.unit_amount / 100).toFixed(2)} ${price.currency}/${price.recurring?.interval || 'month'}`;


                            return <>
                                <FocusedOption
                                    key={index}
                                    title={price.product_name || `Plan ${index + 1}`}
                                    subtitle={price.product_description || ""}
                                    price={`${(price.unit_amount / 100).toFixed(2)}`}
                                    currency={`${price.currency}`}
                                    isFocused={focusedOptionId === index}
                                    onFocusChange={handleFocusChange}
                                    footerText={monthlyPrice}
                                    id={index}
                                    trainerColors={trainerColors}
                                />
                                <div className={classes.spacer} />
                            </>
                        })
                    ) : (
                        <div className={classes.loaderContainer}>
                            <div className={classes.loader}></div>
                        </div>
                    )}
                    {priceInfo &&
                        <>
                            <BlueButton
                                onClick={onSubscribeClick}
                                style={{ backgroundColor: trainerColors.accentColor }}
                                className={classes.subscribeButton}
                            >
                                Continue
                            </BlueButton>
                            <div className={classes.separatorSpacer}></div>
                            <div className={classes.separator}></div>
                            <div className={classes.separatorSpacer}></div>
                        </>
                    }
                    <div className={classes.stripeFooter}>
                        <img
                            src={poweredByStripe}
                            alt="Stripe"
                            className={classes.stripeLogo}
                        />
                    </div>
                </div>
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
};

export default CompanySetup;